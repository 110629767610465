<template>
  <el-dialog v-if="value" v-model="value" width="80%" :show-close="false">
    <div class="lg:h-auto md:h-auto p-5">
      <div>
        <div class="flex flex-col gap-2">
          <p>Elevate Your Experience with Our App!</p>

          <p>Discover a World of Convenience and Features!</p>
          <p>Get Our App for:</p>
          <p>
            Faster Access: Enjoy seamless browsing and instant access to all
            features.
          </p>
          <p>
            Real-time Notifications: Stay updated with exclusive offers and
            important updates.
          </p>

          <p>Download Now and Unleash the Full Potential of Foundation Auto!</p>
        </div>

        <div class="flex flex-row gap-3 py-2 justify-center">
          <a :href="link" @click="handleCloseBanner">Download Now</a>
          <a href="#" @click="handleCloseBanner">Not Now</a>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ElDialog } from "element-plus";
import { defineProps, defineEmits, computed } from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    default: false,
    type: Boolean,
  },
  handleCloseBanner: {
    type: Function,
  },
  link: {
    type: String,
  },
});

const value = computed({
  get() {
    return props?.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
